import React from 'react'
import { Link } from 'gatsby'
import { Layout } from '../components/common'

const NotFoundPage = () => (
    <Layout>
        <div className="container">
            <article className="content" style={{ textAlign: `center` }}>
                <h1 className="content-title">Error 404</h1>
                <section className="content-body">
                    <h2>Page Not Found</h2>
                    Awwww... <Link to="/">return home</Link>
                </section>
            </article>
        </div>
    </Layout>
)

export default NotFoundPage
